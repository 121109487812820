import React from "react"
import { Router } from '@reach/router'
import { connect } from "react-redux";
import IframeTemplate from "../templates/IframeTemplate";
const App = ({ location }) => {
  return (
    <div className="app">
      <Router>
        <IframeTemplate path="iframe/:nid" location={location} />
      </Router>
    </div>
  )
};

export default connect()(App);
