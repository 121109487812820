import React, { useEffect, useState } from "react"
import { withTheme } from "styled-components"
import { Box } from "rebass/styled-components"
import { getValue, getColorName, getIconName, getNodePath } from "../utils"

import SEO from "../components/layout/seo"

import loadable from "@loadable/component"

const Map = loadable(() => import("../components/UI/Map"))

const IframeTemplate = ({ nid, ...props }) => {
  const [node, setNode] = useState(false)
  useEffect(() => {
    if (!isNaN(parseInt(nid))) {
      fetch(
        "/api/solr/plannc/select?q=item_id%3A" +
          nid +
          "&fq=index_id%3Aindex_des_entreprises_uniquement&wt=json&indent=true"
      )
        .then(response => response.json())
        .then(data => {
          if (data.response.docs.length) {
            const dataItem = data.response.docs[0]
            const fetchedNode = JSON.parse(dataItem.ss_node_json)
            fetchedNode.path.path = dataItem.ss_url
            fetchedNode.field_offre_choisie = dataItem.hasOwnProperty(
              "ss_field_offre_choisie$title"
            )
              ? {
                  und: [
                    {
                      nid: {
                        nid: "" + dataItem.is_field_offre_choisie,
                        title: dataItem.ss_field_offre_choisie$title,
                      },
                    },
                  ],
                }
              : { und: null }
            if (dataItem.im_field_categories.length) {
              fetchedNode.field_categories.und = []
              for (var i = 0; i < dataItem.im_field_categories.length; i++) {
                fetchedNode.field_categories.und.push({
                  tid: {
                    tid: "" + dataItem.im_field_categories[i],
                    name: dataItem.tm_field_categories$name[i],
                  },
                })
              }
            }
            setNode(fetchedNode)
          }
        })
    }
  }, [nid])
  if (!node) {
    return <></>
  }
  var path = getNodePath(node, true)
  const markers = [
    {
      center: [
        getValue(node, "field_position", "lon"),
        getValue(node, "field_position", "lat"),
      ],
      color: props.theme.colors[getColorName(node.offre)],
      icon: getIconName(getValue(node, "field_offre_choisie", "nid.nid", "")),
      isCurrent: true,
      nid: node.nid,
      title:
        '<p style="padding-bottom:5px;margin: 0;font-size:1.5em;font-weight:bold;">' +
        node.title +
        '</p><p style="padding-bottom:5px;margin: 0;">' +
        getValue(node, "field_categories", "tid.name") +
        '</p><a href="' +
        path +
        '" target="_blank" style="background:' +
        props.theme.colors.primary +
        ';color:#fff;border-radius:4px;padding:10px;display: inline-block;">Agrandir le plan</a><br>',
      path: path,
      node: node,
      type: node.type,
    },
  ]
  return (
    <Box
      width="100%"
      height={"100%"}
      sx={{
        ".iframe-map": {
          position: "absolute",
          zIndex: 3,
          width: "100%",
          height: "100%",
        },
      }}
    >
      <SEO
        title={node.title}
        description={getValue(node, "field_meta_description", "value", "")}
      />
      <Map className="iframe-map" markers={markers} />
    </Box>
  )
}
export default withTheme(IframeTemplate)
